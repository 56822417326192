export const GRAPHQL_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL as string;
export const IMAGE_ENDPOINT = process.env.NEXT_PUBLIC_IMAGE_ENDPOINT as string;
export const REVALIDATE_TIME = 30;
export const BACKEND_BASE_URL =
  (process.env.NEXT_PUBLIC_BASE_URL as string) || "";
export const NEXT_PUBLIC_STRAPI_API_URL =
  (process.env.NEXT_PUBLIC_STRAPI_API_URL as string) || "";
export const SITE_URL =
  process.env.NEXT_PUBLIC_SITE_URL || "https://www.yardtutor.com";
export const NEXT_PUBLIC_CDN_URL = process.env.NEXT_PUBLIC_CDN_URL as string;
export const NEXT_PUBLIC_GTM = process.env.NEXT_PUBLIC_GTM as string;
export const CAPTCHA_KEY =
  process.env.NEXT_PUBLIC_CAPTCHA_KEY ||
  "6LcgF3cqAAAAACBZpVPgEhm_1iu35xUQImFmo9ws";
