"use client";

import { IMAGE_ENDPOINT } from "@/env";
import { Maybe, UploadFileEntityResponse } from "@/gql/generated/graphql";
import Image, { ImageLoaderProps } from "next/image";
import React, { CSSProperties, FC } from "react";

type Props = {
  image?: Maybe<UploadFileEntityResponse>;
  className?: string;
  id?: string;
  style?: CSSProperties;
  isEager?: boolean;
  notOptimized?: boolean;
  isFullSize?: boolean;
  size?: string;
};

const ResponsiveImage: FC<Props> = (props) => {
  if (!props.image || !props.image.data) return null;
  const attr = props.image.data?.attributes;
  const imagFileName = `${IMAGE_ENDPOINT}/${attr?.hash}${attr?.ext}`;

  const customLoader = ({ src, width }: ImageLoaderProps) => {
    let replaceExt = src.replaceAll(/\.(png|jpg|jpeg)$/g, ".webp");
    return `${IMAGE_ENDPOINT}/${width}w_${replaceExt}`;
  };

  if (attr?.ext === ".svg") {
    return (
      <Image
        style={props.style ?? undefined}
        id={props.id ?? undefined}
        alt={attr?.alternativeText ?? ""}
        height={attr?.height ?? undefined}
        width={attr?.width ?? undefined}
        className={props.className ?? undefined}
        src={imagFileName ?? ""}
        loading={props.isEager ? "eager" : "lazy"}
        sizes={props.size ?? "40vw"}
        unoptimized
      />
    );
  } else {
    return (
      <Image
        style={props.style ?? undefined}
        id={props.id ?? undefined}
        alt={attr?.alternativeText ?? ""}
        height={attr?.height ?? undefined}
        width={attr?.width ?? undefined}
        className={props.className ?? undefined}
        src={`${attr?.hash}${attr?.ext}`}
        loading={props.isEager ? "eager" : "lazy"}
        loader={customLoader}
        sizes={props.size ?? "40vw"}
      />
    );
  }
};

export default ResponsiveImage;
