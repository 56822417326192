"use client";
import { IMAGE_ENDPOINT } from "@/env";
import Image, { ImageLoaderProps } from "next/image";

interface Props {
  src: string;
  width: number;
  alt: string;
  srcSet?: string | undefined;
}

const customLoader = ({ src, width }: ImageLoaderProps) => {
  if (!src.includes("/")) {
    return `${IMAGE_ENDPOINT}/${src}`;
  }
  if (src.includes("/")) {
    const splitSrc = src.split("/");
    return `${IMAGE_ENDPOINT}/${splitSrc[splitSrc.length - 1]}`;
  }
  return src;
};

export default function ArticleImage({ src, width, alt, srcSet }: Props) {
  function generatedSrcset() {
    if (!srcSet) return undefined;
    return srcSet
      .split(",")
      .map((item) => {
        const [url, width] = item.trim().split(" ");
        const uploadIndex = url.indexOf("/uploads/");
        const newUrlValue = url.split("/uploads/").pop();
        return `${IMAGE_ENDPOINT}/${newUrlValue} ${width}`;
      })
      .join(", ");
  }
  return (
    <Image
      src={src}
      loader={customLoader}
      width={width ? width : 640}
      height={425}
      alt={alt}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      className="w-full"
    />
  );
}
